import { Injectable } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { Router } from '@angular/router'

export type QueryParams = { [key: string]: string }

@Injectable({
	providedIn: 'root',
})
export class RouterService {
	constructor(
		private loaderService: LoaderService,
		private router: Router,
	) {}

	navigateByUrl(url?: string, queryParams?: QueryParams): void {
		if (url) {
			const urlWithoutQueryParams: string = url.split('?')[0]
			const serializedUrl: string = this.router.serializeUrl(this.router.createUrlTree([urlWithoutQueryParams]))
			const currentUrlWithoutQueryParams: string = this.router.url.split('?')[0]
			if (serializedUrl.toLowerCase() != currentUrlWithoutQueryParams.toLowerCase()) {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigate([url], { queryParams })
				})
			}
		}
	}
}
